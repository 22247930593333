@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  overflow-y: scroll;
}

.font {
  color: #2e3640;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.mobile-nav-slide-down {
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.mobile-nav-slide-up {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.inputbox {
  position: relative
}
.inputbox input {
  width:100%;
  padding: 10px;
  transition: 0.5s;
  margin-top: 8px;
}
.inputbox textarea {
  width:100%;
  padding: 20px;
  transition: 0.5s;
  margin-top: 8px;
}
.inputbox span {
  position: absolute;
  left:0;
  padding:10px;
  pointer-events: none;
  font-size: 1em;
  transition: 0.5s;
  color: gray;
  text-transform: uppercase;
  margin-top: 8px;
}

.inputbox input:valid ~ span,
.inputbox input:focus ~span {
  color: black;
  transform: translateX(10px) translateY(-7px);
  font-size: 0.7em;
  padding: 0 10px;
  background: white;
  border-left: 1px solid black;
  border-right: 1px solid black;
  letter-spacing: 0.1em;
}

.inputbox textarea:valid ~ span,
.inputbox textarea:focus ~span {
  color: black;
  transform: translateX(10px) translateY(-7px);
  font-size: 0.7em;
  padding: 0 10px;
  background: white;
  border-left: 1px solid black;
  border-right: 1px solid black;
  letter-spacing: 0.1em;
}

/* .inputbox:nth-child(2) input:valid ~ span,
.inputbox:nth-child(2) input:focus ~span {
  background: black;
  color: white;
  border-radius: 2px;
} */

.inputbox input:valid ,
.inputbox input:focus {
  border: 1px solid black;
}